import React from 'react';
import { motion } from 'framer-motion';
import HomeHeader from "../headers/HomeHeader";

const JobsPage = () => {
    const jobCategories = [
        {
            title: "技术岗位",
            jobs: [
                { title: "高级AI研究员", description: "负责前沿AI算法研究和开发，推动公司技术创新。" },
                { title: "全栈开发工程师", description: "开发和维护公司核心产品，确保系统稳定性和可扩展性。" },
                { title: "机器学习工程师", description: "设计和实现机器学习模型，优化AI性能。" }
            ]
        },
        {
            title: "产品岗位",
            jobs: [
                { title: "产品经理", description: "负责AI产品的规划、设计和生命周期管理。" },
                { title: "UX/UI设计师", description: "创造直观、友好的用户界面，提升用户体验。" }
            ]
        },
        {
            title: "市场营销岗位",
            jobs: [
                { title: "市场策划经理", description: "制定和执行市场营销策略，提升品牌知名度。" },
                { title: "内容营销专员", description: "创作高质量的AI相关内容，吸引和教育目标受众。" }
            ]
        }
    ];

    return (
        <div className="bg-gradient-to-br from-purple-900 via-purple-700 to-indigo-800 min-h-screen text-white">
            <HomeHeader/>
            <header className="bg-gradient-to-r from-cyan-500 to-blue-500 py-16">
                <div className="container mx-auto px-4">
                    <motion.h1
                        initial={{opacity: 0, y: -20}}
                        animate={{opacity: 1, y: 0}}
                        transition={{duration: 0.5}}
                        className="text-5xl font-bold mb-4"
                    >
                        诚聘英才
                    </motion.h1>
                    <motion.p
                        initial={{opacity: 0, y: 20}}
                        animate={{opacity: 1, y: 0}}
                        transition={{duration: 0.5, delay: 0.2}}
                        className="text-xl"
                    >
                        加入我们，共同打造AI的未来
                    </motion.p>
                </div>
            </header>

            <main className="container mx-auto px-4 py-16">
                <motion.section
                    initial={{opacity: 0, y: 20}}
                    animate={{opacity: 1, y: 0}}
                    transition={{duration: 0.5}}
                    className="mb-16"
                >
                    <h2 className="text-3xl font-bold mb-6 text-cyan-300">为什么选择我们</h2>
                    <p className="text-lg leading-relaxed text-gray-200">
                        在这里，您将有机会与行业顶尖人才合作，参与最前沿的AI技术开发，
                        并为解决全球性挑战贡献自己的力量。我们提供具有竞争力的薪酬、
                        灵活的工作环境和持续的学习发展机会。
                    </p>
                </motion.section>

                {jobCategories.map((category, index) => (
                    <motion.section
                        key={category.title}
                        initial={{opacity: 0, y: 20}}
                        animate={{opacity: 1, y: 0}}
                        transition={{duration: 0.5, delay: 0.2 * index}}
                        className="mb-16"
                    >
                        <h2 className="text-3xl font-bold mb-6 text-cyan-300">{category.title}</h2>
                        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
                            {category.jobs.map((job, jobIndex) => (
                                <div key={job.title}
                                     className="bg-purple-800 bg-opacity-50 rounded-lg p-6 shadow-lg backdrop-filter backdrop-blur-lg">
                                    <h3 className="text-2xl font-semibold mb-4 text-cyan-200">{job.title}</h3>
                                    <p className="text-lg leading-relaxed text-gray-200">
                                        {job.description}
                                    </p>
                                </div>
                            ))}
                        </div>
                    </motion.section>
                ))}

                <motion.section
                    initial={{opacity: 0, y: 20}}
                    animate={{opacity: 1, y: 0}}
                    transition={{duration: 0.5, delay: 0.6}}
                    className="mb-16"
                >
                    <h2 className="text-3xl font-bold mb-6 text-cyan-300">如何应聘</h2>
                    <p className="text-lg leading-relaxed text-gray-200">
                        如果您对以上职位感兴趣，请将您的简历和求职意向发送至：
                        <a href="mailto:careers@example.com"
                           className="text-cyan-300 hover:underline">careers@example.com</a>。
                        我们的HR团队会在收到您的申请后尽快与您联系。
                    </p>
                </motion.section>
            </main>

            <footer className="bg-purple-900 bg-opacity-50 py-4">
                <div className="container mx-auto px-4 text-center">
                    <p className="mt-2 text-cyan-200">一起探索AI的无限可能</p>
                    <p
                        className="text-gray-300">&copy; 2024 上海索塔沃科技有限公司.
                        &nbsp;&nbsp;
                        IPC备案编号：
                        <a href="https://beian.miit.gov.cn/" target="_blank">沪ICP备2024058273号-1</a>
                    </p>
                </div>
            </footer>
        </div>
    );
};

export default JobsPage;