import React from "react";
import './TeamPage.css';
import HomeHeader from "../headers/HomeHeader";
import { motion } from "framer-motion";

const ActivityItem = ({ title, description, details, image, index }) => {
    const isEven = index % 2 === 0;

    return (
        <div className={`flex flex-col md:flex-row items-center my-8 p-6 activity-item ${isEven ? '' : 'md:flex-row-reverse'}`}>
            <div className="md:w-1/2 p-4">
                <h2 className="text-2xl font-bold mb-4 text-gray-800">{title}</h2>
                <p className="font-semibold mb-4 text-gray-700">{description}</p>
                {details.map((detail, idx) => (
                    <p key={idx} className="text-sm text-gray-600 mb-2">{detail}</p>
                ))}
            </div>
            <div className="md:w-1/2 p-4 flex justify-center">
                <div className="image-container">
                    <img src={image} alt={title} className="activity-image" />
                </div>
            </div>
        </div>
    );
};

const ActivityList = () => {
    const activities = [
        {
            title: "上班更舒心",
            description: "弹性打卡，出行报销",
            details: [
                "上午9点半前灵活打卡，上下班更从容。",
                "一日三餐、半价网红零食，电子餐包替你买单。"
            ],
            image: "/home/team/act3.jpg"
        },
        {
            title: "生活有后援",
            description: "有归所，不漂泊",
            details: [
                "员工宿舍、公租房、住房补贴、人才安居计划，助你安居乐业。",
                "医疗查、带薪病假、免费体检、商业保险、员工家属重疾互助，为你兜底人生意外。",
                "健身房、按摩理疗、丰富体育活动，让你保持最佳状态。"
            ],
            image: "/home/team/act2.jpg"
        },
        {
            title: "轻松的办公氛围",
            description: "舒适办公，快乐工作",
            details: [
                "开放式办公环境，现代化办公设备，让你在舒适中高效工作。",
                "每周五下午的休闲时光，提供各种茶点，游戏区随时开放。",
                "办公区域全天候供应新鲜咖啡和饮料，茶水间配备各类小吃。",
                "公司定期组织团队建设活动，户外拓展、室内团建、文化之旅，丰富你的工作生活。",
                "每个月的员工生日会，精美蛋糕和礼品，让你感受家的温暖。"
            ],
            image: "/home/team/act4.jpg"
        },
    ];

    return (
        <div className="container mx-auto px-4">
            <h2 className="text-3xl font-bold mb-8 text-center text-gray-800">活动列表</h2>
            {activities.map((activity, index) => (
                <ActivityItem
                    key={index}
                    {...activity}
                    index={index}
                />
            ))}
        </div>
    );
};

const TeamPage = () => {
    const teamMembers = [
        { name: '程', role: 'CEO', imageUrl: '/home/aboutUs/cheng.png' },
        { name: 'Bob', role: 'Designer', imageUrl: '/images/bob.jpg' },
        { name: 'Charlie', role: 'Project Manager', imageUrl: '/images/charlie.jpg' },
    ];

    return (
        <div className="team-page-container">
            <div className="bg-gradient-to-br from-purple-900 via-purple-700 to-indigo-800  text-white">

            {/*<div className="bg-gradient-to-br from-purple-900 via-purple-700 to-indigo-800 text-white">*/}
                <HomeHeader />
                <header className="bg-gradient-to-r from-cyan-500 to-blue-500 py-16">
                    <div className="container mx-auto px-4 sm:px-6 lg:px-8" >
                        <motion.h1
                            initial={{ opacity: 0, x: -20 }}
                            animate={{ opacity: 1, x: 0 }}
                            transition={{ duration: 0.5 }}
                            className="text-5xl font-bold mb-4 text-left"
                        >
                            团队风采
                        </motion.h1>
                        <motion.p
                            initial={{ opacity: 0, y: 20 }}
                            animate={{ opacity: 1, y: 0 }}
                            transition={{ duration: 0.5, delay: 0.2 }}
                            className="text-xl text-left"
                        >
                            加入我们，共同打造AI的未来
                        </motion.p>
                    </div>
                </header>
            </div>
            <div className="bg-white">
                <div className="container mx-auto px-4 py-16">
                    <h2 className="text-3xl font-bold mb-8 text-center text-gray-800">团队成员</h2>
                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
                        {teamMembers.map(member => (
                            <motion.div
                                key={member.name}
                                className="bg-gray-100 rounded-lg p-6 shadow-lg"
                                initial={{opacity: 0, y: 20}}
                                animate={{opacity: 1, y: 0}}
                                transition={{duration: 0.5}}
                            >
                                <img src={member.imageUrl} alt={member.name}
                                     className="w-32 h-32 rounded-full mx-auto mb-4 object-cover"/>
                                <h3 className="text-xl font-semibold text-center text-gray-800">{member.name}</h3>
                                <p className="text-center text-gray-600">{member.role}</p>
                            </motion.div>
                        ))}
                    </div>
                </div>
                <ActivityList/>
                <footer className="bg-purple-900 bg-opacity-50 py-4">
                    <div className="container mx-auto px-4 text-center">
                        <p className="mt-2 text-cyan-200">一起探索AI的无限可能</p>
                        <p
                            className="text-gray-300">&copy; 2024 上海索塔沃科技有限公司.
                            &nbsp;&nbsp;
                            IPC备案编号：
                            <a href="https://beian.miit.gov.cn/" target="_blank">沪ICP备2024058273号-1</a>
                        </p>
                    </div>
                </footer>
            </div>
        </div>
    );
};

export default TeamPage;